var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-dashboard-layout',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"loading-text":_vm.$t('general.loading-please-wait'),"headers":_vm.headers,"items":_vm.orders,"items-per-page":10},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text},[_vm._v(" "+_vm._s(_vm.$t('general.' + header.text.toLowerCase()))+" ")])}),0)])]}},{key:"item.agreement",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"cursor-pointer text--blue"},[_c('v-col',[_vm._v(" "+_vm._s(item.agreement === 1 ? 'true' : 'false')+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleString())+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"cursor-pointer text--blue"},[(item.status === 1)?_c('v-col',[_vm._v(" "+_vm._s(_vm.$t('general.created'))+" ")]):_vm._e(),(item.status === 2)?_c('v-col',[_vm._v(" "+_vm._s(_vm.$t('general.in-processing'))+" ")]):_vm._e(),(item.status === 3)?_c('v-col',[_vm._v(" "+_vm._s(_vm.$t('general.completed'))+" ")]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('order-p-d-f-dialog',{attrs:{"order":item},on:{"pdf":_vm.handlePDFOrder}}),_c('div'),_c('order-show-b-t-n',{attrs:{"order":item},on:{"show":_vm.handleClickRow}}),_c('div'),_c('order-delete-dialog',{attrs:{"order":item},on:{"deleted":_vm.handleRemoveOrder}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }