<template>
    <div>
        <v-btn
            class="mt-2"
            color="teal darken-1"
            dark
            small
            v-on="on"
            @click="handleClickRow"
        >
            <v-icon dark>
                mdi-eye
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "OrderShowBTN",
    props: ['order','views'],
    data: function () {
        return {}
    },
    methods: {
        handleClickRow() {
            console.log(this.order);
            let order_id = this.order.id
            console.log(order_id);
            this.$router.push({name: this.views + '.orders.show', params: {id: order_id}})
        },
    },
}
</script>

<style scoped>

</style>
