<template>
    <admin-dashboard-layout>
        <v-data-table
            :loading="loading"
            :loading-text="$t('general.loading-please-wait')"
            :headers="headers"
            :items="orders"
            :items-per-page="10"
            class="elevation-1"
        >

            <template v-slot:header="{ props }">
                <thead>
                <tr>
                    <th v-for="header in props.headers" :key="header.text">
                        {{ $t('general.' + header.text.toLowerCase()) }}
                    </th>
                </tr>
                </thead>
            </template>

            <template v-slot:item.agreement="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col>
                        {{ item.agreement === 1 ? 'true' : 'false' }}
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.created_at="{ item }">
                {{ new Date(item.created_at).toLocaleString() }}
            </template>

            <template v-slot:item.status="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col v-if="item.status === 1">
                        {{ $t('general.created') }}
                    </v-col>
                    <v-col v-if="item.status === 2">
                        {{ $t('general.in-processing') }}
                    </v-col>
                    <v-col v-if="item.status === 3">
                        {{ $t('general.completed') }}
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.actions="{ item }">
                <order-p-d-f-dialog @pdf="handlePDFOrder" :order="item"/>
                <div></div>
                <order-show-b-t-n @show="handleClickRow" :order="item"></order-show-b-t-n>
                <div></div>
                <order-delete-dialog @deleted="handleRemoveOrder" :order="item"/>
            </template>

        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import Order from '../../../models/Order';
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import OrderDeleteDialog from "../../../components/dialogs/OrderDeleteDialog";
import OrderPDFDialog from "../../../components/dialogs/OrderPDFDialog";
import OrderShowBTN from "../../../components/button/OrderShowBTN";

export default {
    name: "order-index",
    components: {AdminDashboardLayout, OrderDeleteDialog, OrderPDFDialog, OrderShowBTN},
    data: function () {
        return {
            loading: false,
            orders: [],
            headers: [
                {
                    text: 'id',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: '#client id',
                    align: 'center',
                    sortable: true,
                    value: 'client_id',
                },
                {text: 'Name', value: 'name'},
                {text: 'Email', value: 'email'},
                {text: 'Phone', value: 'phone'},
                {text: 'Order', value: 'textarea'},
                {text: 'Agreement', value: 'agreement'},
                {text: 'Status', value: 'status'},
                {text: 'Pickup at', value: 'pickup_at'},
                {text: 'Created', value: 'created_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    methods: {
        handleClickRow(order_id) {
            this.$router.push({name: 'admin.orders.show', params: {id: order_id}})
        },
        handleRemoveOrder(id) {
            this.orders = this.$_.filter(this.orders, order => order.id !== id);
        },
        handlePDFOrder(id) {
            this.orders = this.$_.filter(this.orders, order => order.id !== id);
        }
    },
    async mounted() {
        this.loading = true
        this.orders = await Order.get()
        this.loading = false
    },
}
</script>

<style>
thead.v-data-table-header {
    display: none !important;
}
</style>
