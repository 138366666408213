<template>
    <v-dialog
        v-model="dialog"
        width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mt-2"
                color="grey"
                dark
                small
                v-bind="attrs"
                @click="handlePDFOrder">{{ $t('general.pdf') }}
            </v-btn>
        </template>
    </v-dialog>
</template>

<script>
import Order from "../../models/Order";

export default {
    name: "OrderPDFDialog",
    props: ['order'],
    data: function () {
        return {}
    },
    methods: {
        async handlePDFOrder() {
            let id = this.order.id
            const order = new Order(this.order)
            console.log(id, order);
            fetch('/api/order-pdf?order=' + id,
                {responseType: 'aplication:pdf'}
            )
                .then((response) => {
                    return response.blob()
                })
                .then(response => {
                    let fileReader = new FileReader();
                    let base64;
                    // Onload of file read the file content
                    fileReader.onload = (fileLoadedEvent) => {
                        base64 = fileLoadedEvent.target.result;

                        let pdfWindow = window.open(encodeURI(base64), "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=10,left=600,width=720,height=900")
                        pdfWindow.document.write(
                            "<iframe height='900px' width='100%' src='" + encodeURI(base64) + "' landscape='a4' type='application/pdf'></iframe><style scoped media='print'>@page{size: landscape;}body{writing-mode: tb-rl;}@page{size: A4 landscape;}</style>"
                        )
                    };
                    // Convert data to base64
                    fileReader.readAsDataURL(response);
                })
                .catch(e => console.error(e))
        }
    },
}
</script>

<style scoped></style>
