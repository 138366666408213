<template>
    <v-dialog
        v-model="dialog"
        width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mt-2 mb-2"
                color="red lighten-2"
                dark
                small
                v-bind="attrs"
                v-on="on"
            >
                {{ $t('general.delete') }}
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                {{ $t('general.delete') }} {{order.name}}
            </v-card-title>

            <v-card-text>
                {{$t(text)}}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn
                    color="grey"
                    text
                    @click="dialog = false"
                >
                    {{ $t('general.cancel') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="handleDeleteOrder($event)"
                >
                    {{ $t('general.yes') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Order from "../../models/Order";

export default {
    name: "OrderDeleteDialog",
    props: ['order'],
    data: function () {
        return {
            dialog: false,
            text: 'general.are-you-sure',
        }
    },
    methods: {
        async handleDeleteOrder() {
            let id = this.order.id
            const order = new Order(this.order)
            let resp = await order.delete()
            if (resp.status === 200) {
                this.$emit("deleted", id)
                this.dialog = false
            } else {
                this.text = "ERROR"
            }
        }
    },
}
</script>

<style scoped>

</style>
